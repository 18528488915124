<template>
  <!-- <v-text-field
  v-model="inputVal"
    type="text"
    :label='$t("model.prop.name")'
    key="name"
    :rules="rules"
  ></v-text-field> -->
  <div>
    <VueEditor
      v-model="inputVal"
      :rules="rules"
    />
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
   props: ["value"],
    data: () => ({
    name: null,
     rules : [v => !!v || 'Rule is required'],
  }),
  components:{
    VueEditor,
  },
   computed: {
    inputVal: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style>
</style>